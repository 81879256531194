import React from 'react'
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { button, buttonSecondary, buttonOutline, backButton } from '../styles/button.module.scss'

const ButtonOuter = props => {
    const { as: Component, variation, type, className, children, ...rest } = props

    let target = "_self";
    let rel = null;

    if (type.includes('external')) {
        target = "_blank";
        rel = `noopener noreferrer`;
    }

    const getButtonStyle = () => {
        if (variation === 'secondary') {
            return buttonSecondary;
        } else if (variation === 'outline') {
            return buttonOutline;
        } else {
            return null;
        }
    }

    let buttonClassName = [button, getButtonStyle()].join(" ");

    if (className) buttonClassName = [buttonClassName, className].join(" ");

    return (
        <Component
        target={target}
		rel={rel}
        className={buttonClassName}
		{...rest}
        >
        {children}
        </Component>
    )
}

ButtonOuter.defaultProps = {
    as: Link,
    type: 'internal'
}

export default function Button(props) {
    const { type, variation = null, children, ...rest } = props

    let getAs = Link;

		if (type.includes('external')) {
			getAs = OutboundLink;
		}

		if (type === "button") {
			getAs = `button`;
		}

		// if (type === "anchor") {
		// 	getAs = AnchorLink;
		// }

		if (type === "a") {
			getAs = `a`;
		}

    return (

        <ButtonOuter variation={variation} type={type} as={getAs} {...rest}>
            {children}
        </ButtonOuter>
    )
}

export const BackButton = ({as: Component, className, children, ...rest }) => {

    return (
        <Component className={className ? [backButton, className].join(" ") : backButton} {...rest}>
            <span>{children}</span>
        </Component>
    )
}

BackButton.defaultProps = {
    as: `button`
}

Button.Back = BackButton