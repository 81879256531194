import React from 'react'

export default function LostIcon(props) {
    const { fill, className } = props;

    return (

    <svg role="presentation" className={className} x="0px" y="0px"
	 viewBox="0 0 700 620" stroke={`currentColor`} strokeWidth={1}>
<path fill={fill} d="M614.5,336.1L614.5,336.1c-5.2-2.9-10.5-5.7-15.6-8.4c-11.5-6.1-23.3-12-35.1-17.7
	c5.8-12.1,9.4-26.2,11-43c0.6-6.7,1-14.1,1-22.5l0-1.9c0-5.2,0.1-10.5-0.2-15.8c1.3,1.9,2.6,3.8,3.9,5.7c2.5,3.6,5.1,7.3,7.5,11
	c5.1,8,9.3,15.4,12.9,22.6c7.6,15.5,12.5,30.8,14.4,45.5C615.2,319.8,615.3,328,614.5,336.1z M544.9,226.5L544.9,226.5
	c-36.7,5.9-65,19.2-84.3,39.3c8.6,3.2,16.8,6.4,24.6,9.5c9.4,3.8,18.8,7.7,28.1,11.7c5.3-12.3,13.5-24.2,25.1-36.4
	c4.7-5,10.1-10.1,16.6-15.8l1.6-1.4c3.7-3.3,7.6-6.7,11.6-9.8c-2.5,0.3-4.9,0.6-7.4,0.9C555.7,225,550.2,225.6,544.9,226.5z
	 M587.6,231.8C587.6,231.8,587.6,231.8,587.6,231.8C587.6,231.8,587.6,231.8,587.6,231.8L587.6,231.8z M587.6,231.8
	c5.9,5.3,11.8,10.8,17.6,16.7c18.6,18.8,33.2,37.4,44.7,56.8c6.2,10.5,11.6,21.3,15.9,32.2c2.2,5.7,4.2,11.4,5.9,17.1
	c1.7,5.8,3.3,12.1,3.3,18.7c0,0.2-0.1,0.4-0.1,0.5c1.5,1.1,3,2.2,4.5,3.3c7.1,5.5,14.5,11.4,20.2,18.7
	c-3.1-24.3-12.2-49.6-27.4-75.3c-16.3-27.7-38.4-53.2-64-73.8c-6.6-5.3-13.4-10.3-20.4-14.8C587.8,231.9,587.7,231.9,587.6,231.8z
	 M699.2,404L699.2,404c-2.6-6.1-8.4-11.1-13.5-15.6l-0.3-0.3c-9.3-8.1-19.3-15-27.8-20.7c-24.3-16.1-52.3-31.9-88.3-49.6
	c-9.4-4.6-18.8-9.1-28.2-13.4c-0.6-0.4-1.3-0.7-2.2-1.2c-2.4-1.2-4-0.9-4.8,0.8l-47.9,104.4l-31.8,71.2l-5.5,11.2
	c-0.2,0.4-0.4,0.8-0.7,1.2c-32.8-2.9-66.1-1.2-98.7,4.7c-4.8-64.3-34.2-129.1-67.2-183.3c-34.2-56.2-77.9-103.3-128.1-145
	c2.7,0,5.6-0.4,8.3-0.3c11.6,0.3,23.7,2.3,34.3,7.2c2.2,1,3.6-2.3,1.5-3.4c-9.7-5.2-19.2-9.5-30.2-11.1c-4.5-0.7-11.3-2-16.5-0.8
	c2.6-3,5.2-5.9,7.8-8.7c18.1,2.7,35.9,6.2,52.8,13.7c1.5,0.7,2.4-1.5,1-2.3c-14.9-8.3-30.6-15.2-47.4-18.2c2.7-2.8,5.5-5.4,8.4-7.9
	c21.1,3.8,41.3,10.1,59.6,21.7c1.4,0.9,3-1.1,1.6-2.1c-16.6-11.7-34.4-20.8-53.9-25.5c4.1-3,8.5-5.8,13.3-8.2
	c9.5,3.3,19.2,5.9,28.4,10.1c12.1,5.5,22.1,13.2,32.8,20.9c1.5,1.1,4-0.9,2.5-2.5c-13-13.5-33-26.8-52.5-33.3c1-0.4,2-0.7,3.1-1.1
	c4.4-1.4,8.7-2.3,13.1-3c9.7,4.7,19.2,9.1,27.9,15.9c9.5,7.3,17.1,16.1,24.6,25.5c1.5,1.9,4.2-0.2,3-2.3c-9.5-15.7-25-30.8-42.1-40
	c4.7,0.1,9.3,0.5,13.9,1.3c19.3,13,31.9,29.2,43.6,49.9c1.1,1.9,4.3,0.7,3.2-1.3c-7.7-14.3-16.4-31.7-29.1-43.5
	c11.6,4.6,22.5,11.3,32.6,19.7c1.3,1.1,3.4-0.8,2.2-2.2c-8-9-17.7-16.3-28.3-21.8c5.5,1,10.7,2.7,15.1,5.8c2.2,1.6,4.8-1.6,2.6-3.3
	c-6-4.8-12.9-7.2-20.4-8.5c-4.8-0.8-10.3-1.9-15.3-1.3c-3-0.9-6.1-1.7-9.2-2.3c4.6-0.3,9.4-0.4,14.4,0.2c2.2,0.3,2.6-2.8,0.5-3.4
	c-11.5-3-25.4-3.4-37.1,0.9c-1.5,0-3,0-4.5,0.1c6.1-2.2,12.3-3.9,18.6-5.4c1.3-0.3,1.7-2.6,0.1-2.7c-12.5-0.9-29.5,2.8-40.2,11.5
	c-1.2,0.4-2.5,0.8-3.7,1.3c4.3-3.7,8.8-7.5,13.9-9c1.6-0.5,0.7-2.6-0.7-2.6c-8.3,0-14.4,6.6-20.6,11.5c-3.6,2.9-8.2,5.8-12,9.3
	c-1.6,1-3.1,2-4.7,3.1c0,0,0-0.1,0.1-0.1c5.7-6.9,12.7-13.4,19.7-19.1c0.4-0.4,0-1.2-0.5-0.9c-8.6,4.2-15.4,9.2-21.7,16.4
	c-3.9,4.3-8.3,9.7-11.3,15.3c-2.6,2.6-5,5.4-7.1,8.3c3.8-9.9,9.9-18.5,18.5-27.5c1.1-1.2-0.8-3.1-2-2.1
	c-9.2,7.9-18.5,18.9-22.9,31.1c0.8-6.1,2-12.1,4.5-18.4c5.2-13,13.7-24,24.1-33.3c1.3-1.2-0.2-3.3-1.7-2.3
	c-13.3,8.9-26.2,24.5-32.3,41.5c-0.2-1.9-0.4-3.7-0.6-5.6c3.1-8.9,5.2-17.6,11.1-25.8c7.8-10.7,18.1-18.2,29.7-24.3
	c1.2-0.6,0.9-2.9-0.7-2.6c-13.4,2.9-24.8,10.9-33.3,21.6c-2.5,3.2-5,7-7.3,11c0.3-4.9,0.9-9.7,1.7-14.5c10-13.7,20.8-23.8,37-31.5
	c2.1-1,0.9-4-1.4-3.4c-11.8,3.3-23.5,10.7-32.3,20.3c0.9-3,1.9-5.9,3-8.8c6.3-5.6,12.1-11.7,19.6-16.1c9.6-5.6,20.1-8.6,30.9-11.4
	c1.8-0.5,1.4-3.6-0.4-3.7c-12.9-0.4-29.1,4.5-41.5,13.2c2.2-3.7,4.6-7.4,7.2-11c15.7-6,29.4-10.8,47.2-10.2c2.1,0.1,2.7-2.8,0.5-3.3
	c-12.3-2.6-26.8-1.9-39.1,3c3-3.3,6.3-6.4,9.8-9.4c15.6-4.6,29.4-6.2,46.2-4.2c0.8,0.1,1.5-1.1,0.7-1.6c-8.5-5.2-22.3-6.5-34.2-4
	c1.5-1,2.8-2.1,4.3-3c2.3-1.5-0.2-4.7-2.5-3.4c-47.7,27.3-73.7,80.7-64.7,133.3c-6.8-12.6-16-23.9-27-33.2
	c-19-16.2-45-32.5-70.3-33.4c-0.8,0-1.3,1.1-0.4,1.4c5,1.7,9.8,3.7,14.6,5.7C35,75.7,24,80.8,15.4,85.6c-2,1.1-0.9,4.4,1.4,3.5
	c6.1-2.4,12.3-4.8,18.6-6.7c6.8-2,13.3-2.3,20.1-3.5c4.4,2.2,8.7,4.6,12.8,7.1c-14.3-1.4-30.3,3.4-42.6,8.4
	c-1.8,0.7-0.7,3.4,1.1,2.8c9-3,18-4.8,27.5-5.4c7.8-0.5,15.6,0.9,23.3,0.3c2.4,1.7,4.6,3.4,6.9,5.1c-14-2-30,1.1-41.7,6
	c-2.1,0.9-1.4,4.7,1,4.1c8.4-2.1,16.9-3.6,25.5-3.9c8.7-0.3,16.9,1.9,25.4,2.7c2.6,2.5,5.2,5.1,7.6,7.7c-12.8-3.5-27.3-2.8-39.7,0.2
	c-2.6,0.6-2.3,4.7,0.5,4.3c17.1-2.5,31,0.5,46.9,4.7c2.9,3.7,5.5,7.7,8.1,11.7c-3.2-1.3-6.4-2.5-9.3-3.4
	c-10.7-3.6-22.4-5.9-33.8-5.7c-2.3,0-2.2,3.5-0.1,3.9c11.3,2.1,22.2,3.6,32.9,8.4c6,2.7,11.5,7.3,17.6,10.1c0.4,0.8,0.8,1.6,1.1,2.3
	C87,127.5,32.4,132.3,0.4,166.8c-1.2,1.3,0.8,3.2,2.2,2.2c4.5-3.2,9.1-6,13.6-8.6c-1.1,1.5-2.2,2.9-3.1,4.2c-5.7,8.3-8.2,16.2-10,26
	c-0.3,1.5,2.1,2.3,2.6,0.8c2.8-8.2,7.8-16.8,12.9-23.8c3.9-5.3,9.6-9.7,13.6-14.9c4.1-1.6,8.2-3.1,12.4-4.2c-3.8,2.3-7.3,5.1-10.4,8
	c-7.9,7.3-11.2,14.7-15.4,24.4c-0.6,1.5,1.5,2.4,2.3,1c8.7-16.4,24.9-25.8,39.8-35.4c0.5-0.3,0.7-0.7,0.8-1.2
	c2.6-0.3,5.1-0.4,7.7-0.4c-10.5,4.8-19.8,13.8-26.2,21.9c-1,1.2,0.7,3.1,2,2c6.1-5.4,12.3-10.9,19.4-14.9c7.4-4.2,15.1-5.3,23.1-7.5
	c0.1,0,0.2-0.2,0.3-0.2c2.3,0.4,4.6,0.9,6.9,1.5c-11.3,2.7-22.9,9.1-28.6,15.7c-0.7,0.8,0.3,1.9,1.2,1.5c8.2-3.1,15.5-7.7,24.1-9.8
	c6-1.5,12.4-1.2,18.4-2.4c2.4,1,4.8,2.1,7.2,3.3c-3.5,0.4-6.9,0.9-10.1,1.4c-9.3,1.4-17.7,3.4-25.8,8.3c-0.9,0.5-0.2,2,0.7,1.8
	c8.6-1.3,17-3.9,25.7-4.9c6.9-0.8,13.9,0.1,20.8-0.4c0.6,0.4,1.3,0.7,1.9,1.2c-3.6,0.6-7.1,1.4-10.5,2.3c-20.8,0.6-42.7,4.3-61,14.6
	c-1.6,0.9-1.3,4.1,0.9,3.5c11.2-2.7,22-5.3,33-7.1c-3,1.7-5.9,3.5-8.8,5.4c-17.1,0.9-34.4,9.2-46.4,21.1c-1.8,1.8,1,4.2,2.9,3
	c9.9-6.3,19.3-10.4,29.5-13.3c-2.3,2-4.5,4.2-6.7,6.4c-13.5,2.8-27.1,12.6-35,22.6c-1.1,1.4,0.7,3.2,2.1,2.2
	c6.6-4.7,13.3-9.5,20.7-13c0.9-0.4,1.8-0.8,2.7-1.1c-2.6,3.2-5.1,6.6-7.4,10c-4.7,2-9.2,5-13,7.5c-8.7,5.8-16.5,13.1-22.2,21.9
	c-1.9,2.8,1.7,5.5,4.1,3.2c7.1-6.9,14.4-13.5,22.9-18.7c-1.1,2.2-2.2,4.4-3.2,6.6c-4.8,3.6-9.2,8.4-12.9,12.5
	c-7.5,8.1-14.6,17.6-18.2,28c-0.9,2.4,2.3,4.6,3.9,2.4c6-8,11.2-16.4,18.1-23.7c0.8-0.9,1.7-1.7,2.6-2.5c-0.1,0.3-0.2,0.6-0.3,0.9
	c-8.5,10.6-14,25.8-17.5,36.9c-1.1,3.4,3.9,5.7,5.1,2.3c1.9-5.3,4.3-10.5,7-15.5c-2.4,17-1.8,34.3,2.3,51.2c0.5,2,3.5,1.8,3.3-0.4
	c-2.3-24.5,0.1-47.7,7.9-69.5c1.7,21.3,12.2,44.2,18.9,61.1c0.9,2.4,4.3,0.9,3.6-1.5c-4.7-15.8-8.3-32.8-10.3-49.2
	c-1.6-13,1.1-25.4,1.5-38.1c1.2-1.9,2.5-3.9,3.9-5.8c-3.2,25,6.3,53.9,13.3,75.3c0.9,2.8,5.8,1.6,5-1.3c-3.9-15.9-7.6-32.4-8.7-48.8
	c-1.2-17.5,2.6-30.4,7.5-45.7c0.1-0.1,0.2-0.2,0.4-0.3c-3.7,19.8-0.1,43,3.5,60.6c0.8,3.8,6.9,3.2,6.1-0.7
	c-2.6-14.6-3.2-29.3-1.8-44c0.8-8.9,3.2-17.6,4.4-26.4c4.3-3.2,8.7-6.2,13.3-8.9c-10.7,20.6-9.9,50.3-5.8,70.6
	c0.7,3.3,5.5,2.8,5.2-0.6c-1.3-14.5-0.5-29,2.7-43.2c2.7-11.9,9.1-22.5,11.8-34.1c3.5-1.6,7-3.2,10.6-4.7
	c-7.3,16.2-10.2,33.2-9.9,51.4c0.1,3.1,5.4,3.2,5.5,0.1c0.5-20.1,6.7-39.1,16.8-56.3c0.2-0.1,0.5-0.2,0.7-0.2
	c-4.4,13.1-6.6,26.9-9.7,40.4c-0.6,2.5,3.7,3.9,4.5,1.3c4.4-14.2,10.9-27.7,15.1-41.9c0.4-1.2,0.2-2.2-0.1-3.2c0.1,0,0.1,0,0.2-0.1
	c46.2,43.5,87.8,94,120.7,148.2c35.2,57.8,53.3,117.8,69.2,182.8c-26.1,5.8-51.6,14.4-75.7,25.5c-9.9-28.4-30.5-54.3-51.6-75.1
	c-22.3-21.9-47.9-38.6-75.9-52c1.3-0.3,2.5-0.8,3.8-1.1c5.4-1.2,11.2-1.7,16.6-0.7c1.1,0.2,1.4-1.5,0.3-1.7
	c-5.1-1.3-10-2.1-15.2-1.6c-2.2,0.2-5.4,0.4-7.7,1.6c0.9-1.7,1.7-3.3,2.6-4.9c8.7-0.9,17.3-1.3,25.9,0.2c0.8,0.1,0.9-1,0.2-1.2
	c-7.9-2.1-15.9-3.4-24-2.8c0.9-1.6,1.9-3.2,3-4.7c10.2-0.7,20.2-0.2,30,3c0.7,0.2,1.3-0.8,0.5-1.1c-9-3.5-18.3-5.5-27.9-5.4
	c1.6-1.9,3.4-3.7,5.4-5.4c4.7,0.4,9.5,0.5,14.2,1.4c6.2,1.1,11.7,3.5,17.5,5.8c0.8,0.3,1.7-0.9,0.9-1.4c-7.5-4.7-18.2-8.5-28-9.2
	c0.4-0.3,0.8-0.6,1.2-0.8c1.9-1.1,3.8-2.1,5.7-2.9c5,1,9.9,2,14.7,4.1c5.2,2.3,9.8,5.5,14.3,8.9c0.9,0.7,1.9-0.6,1.1-1.4
	c-6.2-6.1-15-11.3-24-13.5c2.1-0.5,4.3-0.8,6.4-0.9c10.4,3.7,18.2,9.7,25.9,17.9c0.7,0.8,2.1-0.2,1.3-1
	c-5.3-5.7-11.3-12.7-18.6-16.7c5.9,0.8,11.8,2.6,17.4,5.3c0.7,0.4,1.5-0.8,0.8-1.3c-4.8-3.2-10.1-5.5-15.7-6.7
	c2.7-0.2,5.3,0,7.7,0.9c1.2,0.5,2-1.3,0.8-1.8c-3.3-1.5-6.8-1.8-10.4-1.5c-2.3,0.2-4.9,0.4-7.1,1.3c-1.5-0.1-3-0.1-4.5-0.1
	c2.1-0.7,4.2-1.3,6.6-1.6c1.1-0.1,0.9-1.6-0.1-1.6c-5.6,0-12.1,1.4-17,4.8c-0.6,0.2-1.3,0.3-1.9,0.5c2.5-1.7,5.1-3.2,7.8-4.6
	c0.6-0.3,0.5-1.4-0.3-1.2c-5.8,1-13.3,4.7-17.2,10c-0.6,0.3-1.1,0.8-1.6,1.1c1.6-2.3,3.2-4.6,5.4-5.9c0.7-0.4,0-1.3-0.6-1.1
	c-3.8,1-5.9,4.7-8.1,7.7c-1.3,1.7-3,3.6-4.3,5.6c-0.7,0.7-1.4,1.5-2,2.3c0-0.1,0.1-0.2,0.1-0.3c1.9-3.8,4.3-7.6,6.9-11.1
	c0.2-0.2-0.1-0.6-0.3-0.4c-3.5,2.9-6,6-8.1,10.1c-1.3,2.5-2.7,5.4-3.4,8.4c-0.9,1.6-1.7,3.2-2.4,4.9c0.6-5.1,2.4-9.9,5.4-15.1
	c0.4-0.7-0.7-1.4-1.2-0.7c-3.4,4.7-6.3,10.9-6.9,17c-0.3-2.9-0.5-5.9-0.1-9c0.9-6.6,3.5-12.7,7.2-18.2c0.5-0.7-0.5-1.5-1.1-0.8
	c-5.1,5.6-9.2,14.4-10.1,22.9c-0.3-0.9-0.6-1.7-0.9-2.6c0.4-4.4,0.4-8.7,2.2-13.1c2.3-5.8,6.2-10.5,10.9-14.6
	c0.5-0.4,0.1-1.5-0.6-1.1c-5.8,2.9-10.2,7.9-12.8,13.8c-0.8,1.8-1.5,3.8-2.1,5.9c-0.4-2.2-0.7-4.5-0.9-6.7c3-7.5,6.8-13.5,13.4-19
	c0.9-0.7-0.1-1.9-1-1.4c-5,2.9-9.6,7.7-12.5,13.1c0.1-1.5,0.2-2.9,0.4-4.4c2.3-3.3,4.2-6.8,7.2-9.7c3.8-3.7,8.3-6.3,12.9-8.8
	c0.8-0.4,0.2-1.8-0.6-1.6c-6,1.3-12.9,5.5-17.6,10.9c0.6-2,1.2-4,2-5.9c6.5-4.6,12.3-8.4,20.6-10.2c1-0.2,0.9-1.6-0.1-1.6
	c-6,0.2-12.6,2.2-17.7,5.9c1-1.9,2.2-3.7,3.4-5.5c6.7-4,12.8-6.2,20.8-7.3c0.4-0.1,0.6-0.7,0.1-0.8c-4.5-1.4-11-0.4-16.2,2.2
	c0.6-0.6,1.1-1.3,1.7-1.9c0.9-1-0.7-2.2-1.6-1.3c-18.9,18.2-24.7,46.1-14.2,69.4c-4.6-5.2-10.2-9.5-16.5-12.6
	c-10.6-5.2-24.5-9.8-36.3-7.2c-0.4,0.1-0.5,0.7,0,0.7c2.5,0.2,4.9,0.6,7.3,1c-4.8,1.8-9.2,5.4-12.6,8.6c-0.8,0.8,0.1,2.1,1.1,1.4
	c2.5-1.8,5.1-3.6,7.8-5.2c2.8-1.7,5.8-2.6,8.7-3.9c2.3,0.5,4.6,1.1,6.7,1.7c-6.7,1-13.5,5.1-18.5,8.8c-0.7,0.5,0.1,1.6,0.8,1.1
	c3.8-2.4,7.8-4.3,12.1-5.7c3.5-1.1,7.3-1.4,10.8-2.5c1.2,0.5,2.4,1.1,3.6,1.6c-6.7,0.8-13.6,4-18.4,7.6c-0.9,0.7-0.1,2.3,1,1.8
	c3.6-1.9,7.4-3.6,11.3-4.8c4-1.1,7.9-1.1,11.9-1.7c1.5,0.8,3,1.7,4.4,2.7c-6.3-0.1-12.9,1.9-18.2,4.7c-1.1,0.6-0.5,2.4,0.7,1.9
	c7.5-3.1,14.2-3.3,22-3.3c1.8,1.4,3.5,2.9,5.1,4.5c-1.6-0.2-3.2-0.4-4.6-0.5c-5.4-0.4-11-0.1-16.2,1.3c-1.1,0.3-0.6,1.9,0.4,1.8
	c5.5-0.4,10.7-0.9,16.1,0c3.1,0.5,6.1,2,9.2,2.6c0.3,0.3,0.5,0.6,0.7,0.9c-20.9-5.9-45.5,2.7-56.2,22.2c-0.4,0.8,0.7,1.4,1.3,0.8
	c1.7-2,3.5-3.8,5.3-5.6c-0.4,0.8-0.7,1.6-1,2.3c-1.7,4.5-1.9,8.4-1.6,13.1c0.1,0.7,1.2,0.8,1.3,0c0.4-4.1,1.6-8.7,3.2-12.5
	c1.2-2.9,3.3-5.6,4.5-8.5c1.7-1.2,3.5-2.4,5.3-3.4c-1.5,1.5-2.8,3.2-3.9,5c-2.8,4.3-3.5,8.1-4.3,13c-0.1,0.8,1,0.9,1.2,0.2
	c2.1-8.6,8.5-14.8,14.2-20.9c0.2-0.2,0.2-0.4,0.2-0.7c1.2-0.4,2.4-0.8,3.6-1.1c-4.3,3.4-7.6,8.7-9.6,13.2c-0.3,0.7,0.7,1.4,1.1,0.7
	c2.2-3.2,4.4-6.4,7.2-9.1c2.9-2.8,6.4-4.2,9.8-6.1c0.1,0,0.1-0.1,0.1-0.1c1.1-0.1,2.2-0.1,3.4-0.1c-4.9,2.5-9.5,6.9-11.4,10.5
	c-0.2,0.4,0.3,0.8,0.7,0.6c3.4-2.4,6.2-5.4,10-7.3c2.6-1.4,5.6-2,8.2-3.2c1.3,0.2,2.5,0.4,3.8,0.7c-1.6,0.6-3.1,1.2-4.6,1.8
	c-4.1,1.7-7.8,3.6-10.9,6.8c-0.3,0.4,0.1,0.9,0.6,0.8c3.8-1.6,7.4-3.7,11.3-5.2c3.1-1.2,6.4-1.6,9.5-2.6c0.4,0.1,0.7,0.2,1.1,0.3
	c-1.5,0.7-2.9,1.4-4.3,2.2c-0.1,0-0.1,0-0.1,0c-9.6,2.7-19.3,6.9-26.6,13.9c-0.6,0.6-0.1,2,0.8,1.5c4.9-2.6,9.7-5,14.6-7.2
	c-1.2,1.1-2.4,2.3-3.5,3.5c-7.8,2.4-14.8,8.2-19,15.1c-0.6,1,1,1.8,1.7,1c3.8-4,7.8-7,12.1-9.5c-0.8,1.2-1.7,2.4-2.4,3.7
	c-5.9,2.8-11,8.9-13.5,14.5c-0.3,0.8,0.7,1.4,1.2,0.8c2.5-3,5-5.9,8.1-8.4c0.4-0.3,0.7-0.5,1.1-0.8c-0.8,1.8-1.6,3.6-2.2,5.5
	c-1.9,1.5-3.7,3.3-5.1,4.9c-3.3,3.7-6.1,8-7.7,12.7c-0.5,1.5,1.4,2.4,2.2,1c2.5-4,5.1-7.9,8.4-11.3c-0.3,1.1-0.4,2.3-0.7,3.4
	c-1.8,2.2-3.3,4.9-4.6,7.2c-2.5,4.6-4.7,9.8-5.1,15c-0.1,1.2,1.6,1.9,2.1,0.6c1.8-4.4,3.3-8.9,5.6-13c0.3-0.5,0.6-0.9,0.9-1.4
	c0,0.1,0,0.2,0,0.3c-2.7,5.9-3.5,13.6-3.8,19.1c-0.1,1.7,2.5,2.2,2.6,0.4c0.3-2.7,0.8-5.4,1.5-8.1c0.9,8.1,3.1,16.1,6.9,23.4
	c0.5,0.9,1.8,0.4,1.5-0.6c-3.9-11-5.5-22-4.4-33c3.2,9.6,10.8,19,15.8,26c0.7,1,2.1-0.1,1.5-1.1c-4-6.7-7.6-14.2-10.5-21.5
	c-2.2-5.8-2.5-11.8-3.8-17.6c0.3-1.1,0.7-2.1,1.1-3.2c1.4,11.9,9.2,24.1,14.9,33.2c0.7,1.2,2.8,0.1,2.2-1.2
	c-3.7-6.9-7.3-14.1-9.7-21.5c-2.6-7.9-2.3-14.3-1.8-21.8c0.1-0.1,0.1-0.2,0.2-0.3c0.6,9.5,4.9,19.8,8.6,27.5c0.8,1.7,3.5,0.7,2.7-1
	c-2.9-6.4-4.9-13.1-5.9-20.1c-0.7-4.2-0.5-8.5-1-12.6c1.6-2,3.4-4,5.2-5.8c-2.6,10.8,1.1,24.4,5.3,33.3c0.7,1.4,2.9,0.7,2.3-0.9
	c-2.3-6.5-3.6-13.3-3.8-20.2c-0.2-5.8,1.5-11.4,1.4-17c1.5-1.2,2.9-2.4,4.5-3.5c-1.5,8.3-0.9,16.5,1.3,24.9c0.4,1.4,2.9,0.9,2.5-0.6
	c-2.1-9.3-1.4-18.8,1.3-27.9c0.1-0.1,0.2-0.1,0.3-0.2c-0.5,6.6,0.1,13.2,0.2,19.7c0,1.2,2.1,1.3,2.2,0.1c0.4-7.1,1.8-14,2.1-21.1
	c0-0.6-0.2-1-0.4-1.4c0,0,0,0,0.1,0c26.4,14.7,51.4,33.1,72.9,54.3c22.5,22.1,37.6,47.1,52.2,74.6c-22.4,10.9-43.5,23.9-62.8,39.2
	c-2,1.6-0.1,5.1,2.2,3.5c66.4-46.1,143.8-66.2,224.4-63.3c38.1,1.4,78.9,3.8,115.6,14.9c37.2,11.2,68.8,28.9,100,51.6
	c1.6,1.1,3.3-1.4,2-2.7c-48.2-45.4-109.6-70.7-173.9-78.4c1.6-3,3.5-7.2,5.7-13c2.4-5.4,6.3-14.6,11.5-27.6
	c5.3-13.1,9-21.8,11.2-26.3c2.2-4.4,4.5-8.8,7-12.9l-0.3-1.4c2.3-3.8,4.1-7,5.3-9.4c1.2-2.4,3.8-8.9,7.9-19.5c1.9-5,5.7-13.5,11-25
	c17.4,7.7,34.9,15.1,52.3,21.9c26,10.1,56.8,21.3,88.3,28.2c8.5,1.9,18.3,3.8,27.9,3.8h0.4c3.7,0,10.5,0,12.3-3.6
	C700.2,407.3,700.1,405.9,699.2,404z M353.8,262.5L353.8,262.5l0.6,0.6c7.8,7.6,16.8,14.2,24.5,19.6c28.8,20.2,60.1,36.8,84.2,49
	c14.1,7.2,28.4,14,42.7,20.6l24.1-53c-23-10.4-45.9-19.9-68.3-28.2c-30.8-11.5-56.9-20.2-83.9-25.1l-1.1-0.2
	c-7-1.3-12.9-2.1-17.6-2.3c-6.4-0.3-10.7,0.5-12.8,2.3c0,0-0.1,0.1-0.1,0.1c-0.9,0.8-1.4,1.9-1.3,3.3
	C345,254,350.3,259.1,353.8,262.5z M359.3,239.2c10.4,0.2,21,2.4,30.8,4.7l0,0c1,0.2,1.9,0.5,2.9,0.7c5.5-4.7,12-7.8,18.2-10.5
	c6.1-2.7,12.4-5.1,18.8-7.2c21.7-7.2,45.6-11.2,71-12c19.9-0.6,40,0.6,59.8,3.4c-14.8-4.7-30.3-8.2-46.3-10.3
	c-32.4-4.2-65.7-2.7-96.4,4.4c-25,5.8-46.2,14.8-63.3,26.7C356.5,239.1,358,239.1,359.3,239.2z M578.6,224.4
	c5.1-12.5,13.6-31.5,12.2-32.2c-3.5-1.7-8.8,9.1-21,27.8L578.6,224.4z M306.7,542.6L306.7,542.6C256,540.7,201,571.1,163,602.1
	c-2.1,1.7,0.8,5.1,2.9,3.6c22.2-15.2,45.7-28.3,70.8-38.2c23.1-9.2,47.3-12.9,70.6-20.9C309.5,545.8,309,542.7,306.7,542.6z
	 M279.7,573.8L279.7,573.8c-26.2,0.3-51.7,12.6-73.1,26.8c-3,2-0.1,6.7,3,4.8c22.5-13.7,46-19.7,70.8-27.4
	C282.8,577.4,282.2,573.8,279.7,573.8z M413.9,539.7L413.9,539.7c-1.6,0.2-1.6,2.7,0.1,2.6c41-3.2,81.7,0.4,121.4,10.8
	c40.7,10.7,74.1,31.1,110.7,50.8c2.1,1.1,4.3-1.8,2.5-3.4C588.5,546.1,491.6,529.7,413.9,539.7z M557.8,572.2L557.8,572.2
	c-4.3-0.8-5.9,5.6-1.6,6.6c32.7,7.6,63.1,22.3,90.7,40.9c0.8,0.5,1.6-0.7,1-1.3C622.7,593.4,592.2,579,557.8,572.2z M489,31.2
	L489,31.2c8.2,0.6,16.2,3.1,22.5,8.6c5.3,4.6,7.8,10.9,12.2,16.3c1.2,1.5,3.8,2,5.6,1.5c0.5-0.1,1-0.3,1.4-0.6
	c0.5-0.3,0.9-0.7,1.3-1.1c0.2-0.2,0.5-0.3,0.7-0.5c11-11.4,24.8-18.9,40.9-19.1c2.4,0,2.3-3.4,0-3.7c-15.7-1.9-31.2,1.6-43.5,11.3
	c-8.3-12.4-27.6-18.6-41.1-16.8C486.4,27.4,486.3,31,489,31.2z M400.6,160.1L400.6,160.1c-1.6,0.2-1.7,2.4,0,2.6
	c5.1,0.4,10.1,2,14.1,5.4c3.3,2.9,4.9,6.8,7.6,10.2c0.8,0.9,2.4,1.3,3.5,0.9c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.6-0.4,0.8-0.7
	c0.2-0.1,0.3-0.2,0.5-0.3c6.9-7.1,15.5-11.8,25.6-11.9c1.5,0,1.4-2.1,0-2.3c-9.8-1.2-19.5,1-27.2,7.1
	C421.1,162.9,409,159,400.6,160.1z M384,81.2C384,81.2,384,81.2,384,81.2C384,81.2,384,81.2,384,81.2L384,81.2z M384,81.2
	c5.1,0.4,10.1,2,14.1,5.4c3.3,2.9,4.9,6.8,7.6,10.2c0.8,0.9,2.4,1.2,3.5,0.9c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.6-0.4,0.8-0.7
	c0.2-0.1,0.3-0.2,0.5-0.3c6.9-7.1,15.5-11.8,25.6-11.9c1.5,0,1.4-2.1,0-2.3c-9.8-1.2-19.5,1-27.2,7.1c-5.2-7.7-17.3-11.6-25.7-10.5
	C382.4,78.9,382.3,81.1,384,81.2z M477.2,104.7c4.1,0.3,8.2,1.6,11.4,4.3c2.7,2.3,4,5.5,6.2,8.2c0.6,0.8,1.9,1,2.8,0.7
	c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.2,0.5-0.4,0.6-0.6c0.1-0.1,0.3-0.1,0.4-0.3c5.6-5.8,12.6-9.6,20.7-9.7c1.2,0,1.2-1.7,0-1.9
	c-8-1-15.8,0.8-22,5.7c-4.2-6.3-14-9.4-20.8-8.5C475.9,102.8,475.8,104.6,477.2,104.7z M477.2,104.7
	C477.2,104.7,477.2,104.7,477.2,104.7C477.2,104.7,477.2,104.7,477.2,104.7L477.2,104.7z"/>
</svg>

    )

}

LostIcon.defaultProps = {
    fill: 'var(--color-primary)',
    className: `w-96`
}